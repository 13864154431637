import Vue from 'vue'
import VueRouter from 'vue-router' 
import store from '@/store/index'
// import moduleRoutes from "../app/src/route"

Vue.use(VueRouter)

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
 

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // ...moduleRoutes, 
    {
      path: '/store',
      name: 'store',
      component: () => import('@/views/admin-clients/Store/StoreView'),
      meta: {
        pageTitle: 'Store',
        breadcrumb: [
          {
            text: 'Store',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/admin-clients/InvoiceList'),
      meta: {
        pageTitle: 'Clients',
        breadcrumb: [
          {
            text: 'Clients',
            active: true,
          },
        ],
      },
    },
    {
      path: '/clients/add-new',
      name: 'add-new-client',
      component: () => import('@/views/settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Add Client',
        breadcrumb: [
          {
            text: 'Add Client',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings-page',
      component: () => import('@/views/settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Settings',
          },
          {
            text: 'General',
            active: true,
          },
        ],
      },
    }, 
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },

 


   
 
 

  
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/choosecompany',
      name: 'choosecompany',
      component: () => import('@/views/choosecompany.vue'),
      props: (route) => ({
       
        ...route.params
    }),
      meta: {
        layout: 'full',
      },
    },

    {
      path: '/admin-users-page',
      name: 'admin-users-page',
      component: () => import('@/views/user/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Admin Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account-migrator-page',
      name: 'account-migrator-page',
      component: () => import('@/views/settings/migration/AccountMigratorPage.vue'),
      meta: {
        pageTitle: 'Account Migrator',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-users-view',
      name: 'admin-users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Users',
            to:'admin-users-page'
          },
          {
            text: 'User Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin-users-edit',
      name: 'admin-users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Manage Users',
        breadcrumb: [
          {
            text: 'Users',
            to:'admin-users-page'
          },
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
   
  
    {
      path: '/settings',
      name: 'settings-page',
      component: () => import('@/views/settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Settings',
          },
          {
            text: 'General',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings/:id',
      name: 'edit-client',
      component: () => import('@/views/settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Settings',
        breadcrumb: [
          {
            text: 'Settings',
          },
          {
            text: 'General',
            active: true,
          },
        ],
      },
    },
 
  
    
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  
  console.log("logged in ");
  const isLoggedIn =   isUserLoggedIn()
//   console.log("forward");

//   var forweardToLogin= localStorage.getItem('needstogoLogin');
//  console.log("forweardToLogin "+forweardToLogin);

//   if (forweardToLogin === "true")
//   {
//     console.log("in forward logged in ");
//     if (  to.name !== 'login') {
//       localStorage.setItem('needstogoLogin',false)
//       console.log("in change root to logged in ");
//       return next({ name: 'login' })
//     }
//   }

  console.log("isLoggedIn", isLoggedIn);
  console.log( "Step 0");
     
  if (isLoggedIn==null&& (to.name == 'login' || to.name == 'choosecompany'))
  {
    console.log( "Step 1");

    next();
  } else if (isLoggedIn==null&&to.name != 'login')
  {
    console.log( "Step 2");
    return next({ name: 'login' })
  } else
    if ( isLoggedIn==false && ( to.name !== 'login' || to.name !== 'choosecompany') ) {
      console.log( "Step 3");
      return next({ name: 'login' })
    } else{
      console.log( "Step 4");

      return next()
    }
 

  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in
  //   if (!isLoggedIn) return next({ name: 'auth-login' })

  //   // If logged in => not authorized
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
